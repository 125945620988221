<template>
	<div>
		<h5>EquipmentMaintenance</h5>
	</div>
</template>

<script>
	export default {
		name: "EquipmentMaintenance",
	};
</script>

<style scoped></style>
